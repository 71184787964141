<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">

    <!--begin: Quick Actions -->
    <div v-if="false" class="kt-header__topbar-item">
      <div
        class="kt-header__topbar-wrapper"
        id="kt_quick_action_toggle"
        data-toggle="dropdown"
        ref="dropdown-1"
      >
        <span class="kt-header__topbar-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M9 15H7.5A1.5 1.5 0 109 16.5V15zm0 0V9h6v6H9zm6 1.5a1.5 1.5 0 101.5-1.5H15v1.5zM16.5 9A1.5 1.5 0 1015 7.5V9h1.5zM9 7.5A1.5 1.5 0 107.5 9H9V7.5zm2 5.5h2v-2h-2v2zm2-2V7.5a3.5 3.5 0 113.5 3.5H13zm3.5 2a3.5 3.5 0 11-3.5 3.5V13h3.5zM11 16.5A3.5 3.5 0 117.5 13H11v3.5zM7.5 11A3.5 3.5 0 1111 7.5V11H7.5z" fill="#000" fill-rule="nonzero"/></g></svg>
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownQuickAction @clicked="handleClicked"></KTDropdownQuickAction>
      </div>
    </div>
    <!--end: Quick Actions -->

    <!--begin: Language bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--langs">
      <div
              class="kt-header__topbar-wrapper"
              id="kt_language_toggle"
              data-toggle="dropdown"
      >
        <span class="kt-header__topbar-icon">
          <img :src="languageFlag || getLanguageFlag" alt="" />
        </span>
      </div>
      <div
              class="dropdown-menu dropdown-menu-fit dropdown-menu-right"
              v-on:click.stop
      >
        <KTDropdownLanguage
                v-on:language-changed="onLanguageChanged"
        ></KTDropdownLanguage>
      </div>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div
        class="kt-header__topbar-wrapper"
        id="kt_user_toggle"
        data-toggle="dropdown"
      >
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome kt-hidden-mobile">{{ $t("COMMON.WELCOME") }} </span>
          <span class="kt-header__topbar-username kt-hidden-mobile">{{ currentAdmin.nickname }}</span>
          <img
            class=""
            alt="Pic"
            :src="currentAdmin.avatar_url ? currentAdmin.avatar_url : '/assets/media/users/default.jpg'"
          />
          <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
          <span
            class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-hidden kt-badge--bold"
            >{{ typeof currentAdmin.nickname === 'string' ? currentAdmin.nickname.substr(0, 2) : '' }}</span
          >
        </div>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownUser></KTDropdownUser>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTDropdownQuickAction from "@/views/theme/topbar/DropdownQuickAction.vue";
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import KTDropdownLanguage from "@/views/theme/topbar/DropdownLanguage.vue";
import i18nService from "@/common/i18n.service.js";
import { mapGetters } from "vuex";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    KTDropdownQuickAction,
    KTDropdownUser,
    KTDropdownLanguage
  },
  methods: {
    onLanguageChanged(e) {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
      // location.href = location.href;
    },
    handleClicked(){

    },
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    ...mapGetters(['currentAdmin'])
  }
};
</script>
