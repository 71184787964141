<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-if="!menu.section && menuCondition(menu)" :menu="menu" :key="i"></KTMenuItem>
      <KTMenuSection v-if="menu.section && menuCondition(menu)" :menu="menu" :key="i"></KTMenuSection>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/aside/MenuItem.vue";
import KTMenuSection from "@/views/theme/aside/MenuSection.vue";
import menuConfig from "@/common/config/menu.config.json";
import {mapGetters} from 'vuex';

export default {
  name: "KTAsideMenu",
  components: {
    KTMenuItem,
    KTMenuSection
  },
  computed: {
    ...mapGetters(['menuItems', 'currentAdmin']),
    /*menuItems() {
      // let permissions = this.permissionList;
      return menuConfig.aside.items;
    }*/
  },
  methods: {
    menuCondition(menu){
      if( menu.hasOwnProperty('condition') ){
        const { condition } = menu;
        if( condition === 'super' ){
          return Boolean(this.currentAdmin.super);
        }
        return true
      }else {
        return true
      }
    }
  }
};
</script>
